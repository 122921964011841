import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import Call from '../../components/Call';
import Booking from '../../components/Booking';

import { graphql } from 'gatsby';

const Contact = props => (
  <Layout bodyClass="page-contact">
    <SEO title="Contact"/>
    <div className="intro intro-small">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Contact</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Call button={false}/>
        </div>
      </div>
      <hr/>
      <div className="row pt-6">
        <div className="col-12">
          <h2 className="brand">Send us a Message</h2>
        </div>
      </div>
      <form data-netlify="true" name="contact" netlify-honeypot="boDo" className="pb-4"
            method="POST"
            action="/contact/success">
        <input type="hidden" name="form-name" value="contact"/>
        <p className="hidden" hidden>
          <label>Don’t fill this out if you're human:<input name="boDo"/></label>
        </p>
        <div className="form-row">
          <div className="col-12 pt-2">
            <label htmlFor="firstName">Name</label>
          </div>
          <div className="col-sm-12 col-md-6 form-group align-bottom">
            <input type="text" className="form-control" id="firstName" name="firstName"
                   placeholder="First Name"/>
          </div>
          <div className="col-sm-12 col-md-6 form-group">
            <label htmlFor="lastName" hidden>Name</label>
            <input type="text" className="form-control" id="lastName" name="lastName"
                   placeholder="Last Name"/>
          </div>
          <div className="col-12 form-group">
            <label htmlFor="email">Email</label>
            <input type="email" className="form-control" id="email" name="email"
                   aria-describedby="emailHelp" placeholder="Enter email"/>
            <small id="emailHelp" className="form-text text-muted">
              We'll never share your email with
              anyone else.
            </small>
          </div>
          <div className="col-12 form-group">
            <label htmlFor="phone">Phone Number</label>
            <input type="phone" className="form-control" id="phone"
                   aria-describedby="phoneHelp" placeholder=""/>
            <small id="phoneHelp" className="form-text text-muted">
              Please format with `(xxx) xxx - xxxx`
            </small>
          </div>

          <div className="col-12 mb-2 form-group">
            <label className="" htmlFor="message">Message</label>
            <textarea rows="10" className="form-control" id="message" name="message"/>
          </div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </div>
      </form>
    </div>

  </Layout>
);
export const query = graphql`
  query ContactQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/contact/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
        }
      }
    }
  }
`;
export default Contact;
